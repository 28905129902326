.full-width {
  width: 100%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}