@font-face {font-family: 'SF Pro Text'; src: url("../fonts/sf-pro/SFProText-Regular.eot"); src: url("../fonts/sf-pro/SFProText-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/sf-pro/SFProText-Regular.woff2") format("woff2"), url("../fonts/sf-pro/SFProText-Regular.woff") format("woff"), url("../fonts/sf-pro/SFProText-Regular.ttf") format("truetype"); font-weight: 400; font-style: normal; font-display: swap;}
@font-face {font-family: 'SF Pro Text'; src: url("../fonts/sf-pro/SFProText-Medium.eot"); src: url("../fonts/sf-pro/SFProText-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/sf-pro/SFProText-Medium.woff2") format("woff2"), url("../fonts/sf-pro/SFProText-Medium.woff") format("woff"), url("../fonts/sf-pro/SFProText-Medium.ttf") format("truetype"); font-weight: 500; font-style: normal; font-display: swap;}
@font-face {font-family: 'SF Pro Text'; src: url("../fonts/sf-pro/SFProText-Semibold.eot"); src: url("../fonts/sf-pro/SFProText-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/sf-pro/SFProText-Semibold.woff2") format("woff2"), url("../fonts/sf-pro/SFProText-Semibold.woff") format("woff"), url("../fonts/sf-pro/SFProText-Semibold.ttf") format("truetype"); font-weight: 600; font-style: normal; font-display: swap;}
@font-face {font-family: 'SF Pro Text'; src: url("../fonts/sf-pro/SFProText-Bold.eot"); src: url("../fonts/sf-pro/SFProText-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/sf-pro/SFProText-Bold.woff2") format("woff2"), url("../fonts/sf-pro/SFProText-Bold.woff") format("woff"), url("../fonts/sf-pro/SFProText-Bold.ttf") format("truetype"); font-weight: 700; font-style: normal; font-display: swap;}

// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@import "~antd/dist/antd.less";
// theme overrides
@primary-color: #4E6CFF;
 //@blue-5;
@border-radius-base: 4px;
@border-color-base: hsv(0, 0, 82%); // base border outline a component
@border-color-split: hsv(0, 0, 90%); // split border inside a component
@background-color-base: #F3F6FC;

@table-header-bg: white; //rgba(0,0,0,0);
@table-header-color: #4e555b;
@table-row-hover-bg: white; //rgba(225,245,254 , 0);
@table-row-hover-bg-custom: rgba(225,245,254 , 0.2);

@font-family: "SF Pro Text", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
// 'Open Sans',Helvetica,sans-serif;
@tab-bar-margin: 0;

@layout-body-background: none;
@layout-sider-background: none;

@table-padding-horizontal: @space-l;
@card-padding-base: @space-m;
@tabs-vertical-margin: 0;

// @alert-success-border-color: none;
// @alert-info-border-color: none;
// @alert-warning-border-color: none;
// @alert-error-border-color: none;

@import "./spacing.less";
@import "./utilities.less";
@import "./editor.less";
// @import "./prism.less";

// @import "./fontawesome/font-awesome.less";
// @fa-font-path: "../../../public/fonts";

@title-color: #121212;

html, body {
  background-color:#F3F6FC; //#f8fafd;
  font-family: @font-family;
  font-size: 14px;
}

b, strong {
  font-weight: 500;
}

.subtitle {
  font-size: 13px !important;
  position: relative;
  text-transform: uppercase;
  color: #78909C;
  font-weight: 600;
  padding-right: @space-m;
}

table td {
  vertical-align: top;
}

.ant-divider-horizontal {
  margin: @space-l 0 !important;
}
.ant-divider-inner-text {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  color: rgba(0,0,0,0.5);
}

.ant-btn-group > .ant-btn-sm {
  line-height: inherit;
}

.ant-drawer-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid @border-color-split;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  z-index: 10;
}

.ant-badge-status-text {
  font-size: inherit;
}
.ant-dropdown-menu-item-group-list {
  list-style-type: none;
  padding: 0;

  > li {
    // padding-left: @space-l;
  }
}

.ant-tag {
  line-height: 22px;
  height: 24px;
  // vertical-align: top;
  &.small {
    font-size: 12px;
    line-height: inherit;
    height: inherit;
  }
  &.xsmall {
    font-size: 10px;
    line-height: 15px;
    height: inherit;
  }
  &.large {
    font-size: 16px;
    line-height: inherit;
    height: inherit;
  }
  border-left-width: 2px;
  border-top: none;
  border-right: none;
  border-bottom: none;
}

.anticon-down {
  font-size: 12px;
  transform: scale(0.83333333) rotate(0deg)
}

.ant-tabs-bar {
  margin-bottom: 0;
}

.ant-tabs-nav .ant-tabs-tab {
  padding-left: @space-m !important;
  padding-right: @space-m !important;
}

.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}
// .ant-tabs-line > .ant-tabs-bar {
//   margin: 0;
// }

.table-th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;  
}

.ant-tooltip-inner {
  word-break: inherit;
}

.ant-table-column-title {
  display: inline-block;
}

.ant-table-tbody > tr:hover > td {
  border-bottom: 1px solid rgba(78,108,255,0.4);
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  position: relative;
  vertical-align: top;
  word-break: normal;
}

.ant-table-thead > tr > th {
  position: relative;
  vertical-align: middle !important;
  word-break: normal;
}

.ant-table-thead > tr > th.actions {
  // display: block;
  text-align: right;

  .ant-table-header-column {
    display: block; 
  }

  .ant-table-column-title {
    display: block;
    text-align: right;
  }
}

.ant-table-tbody > tr > td.actions {
  text-align: right;
}

// .ant-table.ant-table-middle .ant-table-expanded-row-fixed {
//   margin: 0;
//   padding: 0;
// }

.table-all-total {
  font-size: 14px;
  font-weight: bold;
  background-color: darken(white, 1%);
}

.table-border-bottom {
  border-bottom: 1px solid @border-color-base !important;
}
.table-border-right {
  border-right: 1px solid @border-color-base !important;
}

// .ant-table .ant-table-thead > tr:not(:last-child) > th.test:not(:last-child) {
//   border-bottom: 1px solid @border-color-base; // split e8e8e8
// }

// .ant-table .ant-table-thead > tr > th.test {
//   border-right: 1px solid @border-color-base; // split e8e8e8
// }

//, .ant-table-bordered .ant-table-tbody > tr > td

.ant-cohort {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: @space-m;

  .ant-cohort-table {
    display: table;
    width: 100%;
    height: 100%;
  }
  .value {
    display: table-cell;
    vertical-align: middle;
  }
}

.ant-table-small > .ant-table-content > .ant-table-header > table, .ant-table-small > .ant-table-content > .ant-table-body > table, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}

.no-border {
  border: none !important;

  .ant-table-small {
    border-left: none;
    border-right: none;
  }
}

.no-margin {
  margin: 0 !important;
}

#root {
  width: 100%;
  min-height: 100vh;
  // background-color: #f2f2f3; //#ececec;
}

// copied from core base.less
.link {
  color: @link-color;
  background: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color .3s ease;

  &:hover {
    color: @link-hover-color;
  }

  &:active {
    color: @link-active-color;
  }

  &:active,
  &:hover {
    outline: 0;
    text-decoration: none;
  }

  &[disabled] {
    color: #ccc;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.splash {
  min-height: 100vh;

  background: linear-gradient(133deg,#0F1FAC,#2B6DE0);
  // background: linear-gradient(133deg,#FF1A2F,#FE1D5B,#FE417C);

  // rose
  // background: #FBD3E9; /* fallback for old browsers */
  // background: -webkit-linear-gradient(to left, #FBD3E9 , #BB377D); /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to left, #FBD3E9 , #BB377D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  // day tripper
  // background: #f857a6; /* fallback for old browsers */
  // background: -webkit-linear-gradient(to left, #f857a6 , #ff5858); /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to left, #f857a6 , #ff5858); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  // blush
  // background: #55B7E0; /* fallback for old browsers */
  // background: -webkit-linear-gradient(to bottom right, #55B7E0 , #2340CE); /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to bottom right, #55B7E0 , #2340CE); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  // background-image: -webkit-linear-gradient(left, #02AAB0 0%, #00CDAC 100%);
  // background-image: -o-linear-gradient(left, #02AAB0 0%, #00CDAC 100%);
  // background-image: linear-gradient(to right, #02AAB0 0%, #00CDAC 100%);
  padding-bottom: @space-xl;

  &-logo {
    display: block;
    margin: 0 auto;
    padding: @space-xl 0;
    width: 300px;
  }

  &-content {
    background-color: white;
    max-width: 540px;
    margin: 0 auto;
    padding: @space-l;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    overflow: hidden;
  }

  .google-svg {
    vertical-align: middle;
    margin-right: @space-m;
  }

  &-layout {
    display: table;
    width: 100%;
    table-layout: fixed;
  
    &-steps {
      display: table-cell;
      vertical-align: top;
      border-right: 1px solid black;
      width: 220px;
    }

    &-content {
      display: table-cell;
      vertical-align: top;
      padding-left: @space-l;
    }
  }
}


.list {
  // border: 1px solid @border-color-base;
  // border-radius: @border-radius-base;
  display: table;
  width: 100%;
  box-shadow: @box-shadow-base;

  &-item {
    display: table-row;

    > div {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      padding: @space-m;
      position: relative;
      color: inherit;
    }

    &:hover {
      // background-color: #eaf8fe;
      background-color: rgba(3,169,244,0.05);
    }

    &:not(:last-child) > div {
      border-bottom: 1px solid @border-color-split;
    }

    &-title {
      font-size: 18px;
    }

    &-icon {
    }
  }
}

.layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  position: relative;
  min-height: 100vh;
}

.layout-content {
  padding: @space-m @space-l @space-xl @space-l;
}

.layout-aside {
  position: relative;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;

  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.layout-header {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 60px;
  overflow: hidden;
//   background-image: linear-gradient(-225deg, #2340CE 0%, #55B7E0 100%);
//  background-image: ;
 background-image: linear-gradient(90deg, #4138BE 0%, #5E79FF 100%);
  &::after {
    clear: both;
  }

  &-logo {
    display: block;
    float: left;
    transition: all 0.3s ease;
    padding-top: 15px;
    text-align: center;
    width: 220px;
    &.folded {
      width: 70px;
    }
  }

  &-breadcrumb {
    display: block;
    float: left;
    padding-left: @space-m;
    height: 60px;
    line-height: 60px;

  }

  &-item {
    display: inline-block;
    color: white;
    padding: 0 24px;
    transition: all 0.3s ease;
    cursor: pointer;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    
    // &-picture {
    //   display: table-cell;
    //   line-height: 60px;
    //   padding-right: @space-m;
    // }

    &-name {
      display: table-cell;
      line-height: 60px;

      &-top {
        display: inline-block;
        line-height: 16px;
        vertical-align: middle;
      }
      &-sub {
        font-size: 10px;
      }
    }

    &-icon {
      display: table-cell;
      line-height: 60px;
      padding-left: @space-s;
    }

    &:hover, &.selected {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .ant-select > .ant-select-selector {
    background: none;
    border-style: dashed;
    border-color: #353535;
  }

  &-user-container {
    // display: inline-block;
    float: right;
    height: 60px;
  }

  &-user {
    display: table;
    color: white;
    padding: 0 @space-l;
    transition: all 0.3s ease;
    cursor: pointer;
    
    &-picture {
      display: table-cell;
      line-height: 60px;
      padding-right: @space-m;
    }

    &-name {
      display: table-cell;
      line-height: 60px;

      &-top {
        display: inline-block;
        line-height: 16px;
        vertical-align: middle;
      }
      &-sub {
        font-size: 10px;
      }
    }

    &-button {
      display: table-cell;
      line-height: 60px;
      padding-left: @space-m;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.layout-aside .layout-sider {

  background-color: none;
  // border-right: 2px solid #D6DCF1;
  flex: 0 0 220px;
  max-width: 220px;
  min-width: 220px;
  width: 220px;
  position: relative;
  padding: 24px 0;
  // padding: 24px 24px 36px 24px;
  overflow: visible;
  transition: all 0.3s ease;

  &.folded {
    width: 70px;
  }
}

.layout-sider.collapse {
  flex: 0 0 70px;
  max-width: 70px;
  min-width: 70px;
  width: 70px;

  .layout-menu-item svg, .layout-menu-item .anticon {
    margin: 0;
  }

  .layout-menu-submenu {
    display: none;
  }

  .layout-menu-item-chevron {
    display: none;
  }
}

.layout-sider.collapse .layout-menu-hider {
  width: 70px;
}


.layout-main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;

  h1 {
    position: relative;
    font-size: 20px;
    padding: @space-m 0 @space-l 0;
    // border-bottom: 1px dashed @border-color-base;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;

    .actions {
      position: absolute;
      height: 36px;
      right: 0;
      top: @space-m;
      text-align: right;
      line-height: 36px;
      font-size: 14px;
      font-weight: normal;
      color:  @text-color;
    }
  }

  // h2 {
  //   position: relative;
  //   text-transform: uppercase;
  //   font-size: 14px;
  //   color: #78909C;
  //   padding: @space-m;
  //   margin: 0;

  //   .actions {
  //     position: absolute;
  //     height: 32px;
  //     right: @space-m;
  //     top: @space-m;
  //     text-align: right;
  //     // line-height: 28px;
  //     font-size: 14px;
  //     font-weight: normal;
  //     color:  @text-color;
  //   }
  // }
}

.layout-menu {
  &-title {
    font-size: 12px;
    padding: 0 12px 12px 24px;

    .ant-select > .ant-select-selector {
      background: none;
      border-style: dashed;
      border-color: #353535;
    }
  }

  &-submenu {
    padding: 6px 0 6px 32px;
  }

  &-subitem {
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    overflow: hidden;
    padding: 0 24px 0 20px;
    border-left: 1px solid rgba(0,0,0,0.2);

    &:hover, &.active {
      color: @primary-color;
      border-left: 1px solid #4E6CFF;

      svg, .anticon {
        color: @primary-color;
      }
    }
  }

  &-item {
    // padding: 0 28px;
    position: relative;
    font-size: 15px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #353535;
    overflow: hidden;
    padding: 0 24px;
    border-left: 2px solid rgba(0,0,0,0);
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;

    &:hover, &.active {
      // color: @primary-color;
      border-left: 2px solid #4E6CFF;
      background-color: rgba(65,132,252,.05);

      svg, .anticon {
        color: @primary-color;
      }
    }

    svg, .anticon {
      color: #546E7A;
      font-size: 14px;
      margin-right: 8px;
      width: 14px !important;
    }

    &-chevron {
      position: absolute;
      margin-top: 12px;
      right: 6px;
    }
  }

  &-hider {
    z-index: 0;
    position: fixed;
    bottom: 0px;
    width: 220px;
    // line-height: 36px;
    color: @primary-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: 200;
    cursor: pointer;
    height: 100px;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, #F3F6FC 55%);

    > div {
      margin-top: 60px;
    }
    &:hover {
      // background-color: #9EA2B5;
      color: black;
      transition: all .3s ease;
    }
  }
}

.breadcrumb {
  color: rgba(255, 255, 255, 0.7);

  a, a:visited {
    color: white;
  }

  &-spacer {
    padding: 0 @space-s;
  }
}

.paper {
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
}

.block {
  border-radius: @border-radius-base;
  background-color: white;
  box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.1);

  h2 {
    position: relative;
    margin: 0;
    // margin: 0 0 @space-l 0;
    padding: @space-m;
    color: #78909C;
    // color: @table-header-color;
    // text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid lighten(#CFD8DC, 10%);
    line-height: 32px;

    &.no-margin {
      margin: 0;
    }

    &.mini {
      margin: 0;
      padding: @space-s @space-m;
      font-size: 12px;
      font-size: 300;

      .actions {
        top: 12px;
      }
    }

    .actions {
      position: absolute;
      height: 32px;
      line-height: initial;
      right: @space-m;
      top: @space-m;
      text-align: right;
      // line-height: 28px;
      font-size: 14px;
      font-weight: normal;
      color:  @text-color;
    }
  }
}

.actions-teleport {
  position: absolute;
  top: @space-m;
  right: @space-m;
}

// &.blue { backbround-color: #cfdfff; }
// &.cyan { background-color: #d0f0fd; }
// &.teal { background-color: #c2f5e9; }
// &.green { background-color: #d1f7c4; }
// &.yellow { background-color: #ffeab6; }
// &.orange { background-color: #fee2d5; }
// &.red { background-color: #ffdce5; }
// &.pink { background-color: #ffdaf6; }
// &.purple { background-color: #ede2fe; }
// &.gray { background-color: #eee; }

pre[class*="language-"] {
  margin: 0;
  border-radius: 0;
}

pre.code {
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  // border-radius: 3px;
}

.size-10 { font-size: 10px;}
.size-11 { font-size: 11px;}
.size-12 { font-size: 12px;}
.size-13 { font-size: 13px;}
.size-14 { font-size: 14px;}
.size-15 { font-size: 15px;}
.size-16 { font-size: 16px;}
.size-18 { font-size: 18px;}
.size-20 { font-size: 20px;}
.size-22 { font-size: 22px;}
.size-24 { font-size: 24px;}

.semibold { font-weight: 600; }
.vertical-center {
  vertical-align: middle !important;
}

.widget-title {
  // color: #78909C;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
  line-height: 20px;
}

.dashed-bottom-2 { border-bottom: solid 2px #ecf0f2; }
.dashed-bottom-1 { border-bottom: dashed 1px #ecf0f2; }
.dashed-top-1 { border-top: dashed 1px #ecf0f2; }
.dashed-top-2 { border-top: solid 2px #ecf0f2; }
.dashed-right-1 { border-right: dashed 1px #ecf0f2; }
.dashed-right-2 { border-right: solid 2px #ecf0f2; }
.dashed-left-2 { border-left: solid 2px #ecf0f2; }

.border-bottom-2 { border-bottom: solid 2px #ecf0f2; }
.border-bottom-1 { border-bottom: solid 1px #ecf0f2; }
.border-top-1 { border-top: solid 1px #ecf0f2; }
.border-top-2 { border-top: solid 2px #ecf0f2; }
.border-right-1 { border-right: solid 1px #ecf0f2; }
.border-right-2 { border-right: solid 2px #ecf0f2; }
.border-left-1 { border-left: solid 1px #ecf0f2; }
.border-left-2 { border-left: solid 2px #ecf0f2; }

.color-kpi-blue { color: rgba(3, 178, 214, 1); }
.color-kpi-pink { color: rgba(233, 30, 99, 1); }
.color-kpi-purple { color: rgba(103, 58, 183, 1); }
.color-kpi-orange { color: rgba(255, 87, 34, 1); }
.color-kpi-teal { color: rgba(0, 150, 136, 1); }
.color-kpi-lime { color: rgba(139, 195, 74, 1); }
.color-kpi-indigo { color: rgba(63,81,181, 1); }
.color-black-40 { color: rgba(0,0,0, 0.4); }
.color-black-50 { color: rgba(0,0,0,0.5); }
.color-green { color: #4CAF50; }
.color-red { color: #F44336; }
.color-orange { color: #FF9800; }

.color-grey { color: #78909C; }
.corner-blue { border-left-color: rgba(3, 178, 214, 1) !important;  }
.corner-pink { border-left-color: rgba(233, 30, 99, 1) !important;  }
.corner-purple { border-left-color: rgba(103, 58, 183, 1) !important;  }
.corner-orange { border-left-color: rgba(255, 87, 34, 1) !important;  }
.corner-teal { border-left-color: rgba(0, 150, 136, 1) !important;  }
.corner-lime { border-left-color: rgba(139, 195, 74, 1) !important;  }

.bg-white { background-color: white; }

.opacity-30 { opacity: 0.3; }
.opacity-50 { opacity: 0.5; }
.opacity-60 { opacity: 0.6; }

.dash {

  &-live {
    line-height: 66px;
    padding: 0 @space-m;
  }

  &-kpi {
    padding: @space-m;
    overflow: hidden;
    display: table-cell;
    width: 16.66%;
    transition: all .3s ease;
    position: relative;
    user-select: none;

    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: @table-row-hover-bg-custom
      }
    }

    &-visible {
      position: absolute;
      right: 0;
      bottom: 0;
      line-height: 10px;
    }

    &-container {
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    &-value {
      font-weight: 600;
      // color: #4A4A4A;
      color: #607D8B;
      font-size: 18px;
      margin: @space-s 0;
    }

    &-graph {
      height: 30px;
    }

    &-variation {
      font-size: 12px;
      color: #9B9B9B;
      margin-top: @space-s;
    }
  }

  &-table {
    width: 100%;
    table-layout: fixed;
    margin: 0;
    padding: 0;

    td {
      vertical-align: top;
      padding: 0;
    }

    &-stats {

      .ant-table-tbody > tr > td {
        padding: 5px 8px !important;
        font-size: 13px !important;
        border-bottom: 1px solid #DBE5E9 !important;
        color: #546E7A;
      }

      .ant-table-thead > tr > th {
        background: none;
        font-size: 13px;
        font-weight: inherit;
        color: #78909C !important;
        border-bottom: 1px solid #DBE5E9 !important;
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-bottom: 12px !important;
        // padding: 0 0 12px 0 !important;
        &:last-child {
          padding-right: 0 !important;
        }
      }        
    
      // &-td {

      //   &.label {
      //     color: #546E7A;
      //   }

        .value {
          text-align: right;
          color: black; //#353535;
          // font-weight: 600;
        }
    }
  }
}

// .ant-table-tbody > tr > td.dash-table-stats-td {
//   padding: 5px 8px !important;
//   font-size: 13px !important;
//   border-bottom: 1px solid #CFD8DC !important;
// }

// .ant-table-tbody > tr > th.dash-table-stats-td {
//   color: #78909C !important;
// }

.user-picture {
  width: 24px;
  height: 24px;
  border-radius: 50%;

  &.medium {
    width: 65px;
    height: 65px;
  }

  &.large {
    width: 80px;
    height: 80px;
  }
}

.user-picture-icon {
  font-size: 24px;
  line-height: 24px;
  opacity: 0.3;

  &.medium {
    font-size: 65px;
    line-height: 65px;
  }

  &.large {
    font-size: 80px;
    line-height: 80px;
  }
}

.table-icon {
  color: rgba(0, 0, 0, 0.3);
  margin-right: @space-xs;
  font-size: 13px;
}

.subsider {
  width: 30%;
  border-right: solid 2px #CFD8DC;

  &-title {
    font-weight: 600;
    line-height: 26px;
    // border-bottom: dashed 1px #CFD8DC;
    padding: @space-s @space-m;
  }

  &-attribute {
    border-bottom: dashed 1px #CFD8DC;
    font-size: 12px;
    padding: @space-s @space-m;

    &:hover {
      background-color: @table-row-hover-bg-custom;
    }

    &::before {
      content: " ";
      display: table;
    }

    &::after {
      clear: both;
      content: " ";
      display: table;
    }

    &-key {
      float: left;
      font-weight: 600;
      // color: black;
      // color: @primary-color; //@link-active-color;
      color: #455A64;
      font-size: 13px;
      margin-right: @space-m;
    }
  }
}

// .ant-layout-has-sider .ant-layout-content {
//   width: 70%;
//   padding: @space-m @space-m @space-xl @space-m;
// }

.timeline {
  &-main-date {
    width: 140px;
    display: inline-block;
  }

  &-main-info {
    margin-left: 70px;
    padding-left: 78px;
    border-left: 1px solid #CFD8DC;
    position: relative;
  }

  &-main-arrow {
    position: absolute;
    left: -7px;
    bottom: -6px;
    color: #CFD8DC;
  }
  &-hit-image {
    max-height: 150px;
  }
}

.no-pointer {
  cursor: inherit;
}

.no-select {
  user-select: none;
}

.channels-cascader {
  .ant-cascader-menu {
    height: 250px;
  }
}

.key-value-table {
  width: 100%;

  > tbody > tr:not(:last-child) > td, > tbody > tr:not(:last-child) > th {
    // border-bottom: 1px solid #CFD8DC;
  }
  
  // > tbody > tr:nth-child(odd) {
  //   background-color: rgba(0,0,0,0.03);
  // }
  > tbody > tr:nth-child(odd) > th {
    background-image: linear-gradient(to right, white 0%, darken(#F3F6FC, 1%) 100%);
    // background-color: rgba(0,0,0,0.03);
  }
  > tbody > tr:nth-child(odd) > td {
    background-image: linear-gradient(to left, white 0%, darken(#F3F6FC, 1%) 100%);
    // background-color: rgba(0,0,0,0.03);
  }

  > tbody > tr > th, > tbody > tr > td {
    vertical-align: top;
    padding: @space-s @space-m;
  }

  > tbody > tr > th {
    text-align: right;
    // background-color: rgba(236,239,241,0.3);
    font-weight: 500;
  }
}


.custom-filter-dropdown {
  padding: @space-s;
  border-radius: 3px;
  background: #fff;
  box-shadow: @box-shadow-base;
  max-width: 250px;
}

// .wide-modal {
//   .ant-modal {
//     top: 30px;
//     // left: 30px;
//     bottom: 30px;
//     // right: 30px;
//     // position: fixed;

//     &-content {
//       margin: 0 30px
//     }

//     &-body {
//       padding: 0;
//       // height: 100%;
//       // overflow: auto;
//     }
//   }
// }

.drawer {
  .ant-drawer {
    &-body {
      padding: 0;
    }
  }

}

.ant-drawer.with-tabs {
  .ant-drawer-header {
    padding: 0;
    min-height: 55px;
    overflow: hidden;
    border: none !important;

    .title {
      position: absolute;
      left: 24px;
      top: 16px;
    }

    .ant-tabs-nav {
      height: 55px;
    }
  }

  .ant-drawer-body {
    padding: 0;
  }
}

.ant-drawer {

  .ant-drawer-content-wrapper {
    // transition: width 0.2s ease;
  }

  &.drawer-bg {
    .ant-drawer-content {
      background: #F3F6FC;
    }
    .ant-drawer-header {
      // padding: 0;
      border: none;
      background: none;
    }
  }

  &.drawer-tabs {
    .ant-drawer-header {
      background: none;
    }

    .ant-drawer-body {
      margin-top: -55px;
    }

    .ant-tabs-nav {
      height: 55px;
    }

    // .ant-tabs:first-of-type .ant-tabs-content:first {
    //   margin-top: 24px;
    // }
  }
}

.table-attributes {
  width: 100%;

  tr:not(:last-child) {
      border-bottom: dashed 1px #CFD8DC;
  }

  td {
    font-size: 12px;
    padding: @space-s @space-m;

    &:hover {
      background-color: @table-row-hover-bg-custom;
    }
  }
}

.attribution-bar {
  width: 150px;

  .ant-progress-status-success .ant-progress-text {
    color: rgb(139, 195, 74);
  }
}

.badge-default .ant-badge-count {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}

.block-bordered {
  border-radius: 5px;
  border: 1px solid @primary-color;
  padding: 24px;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 0px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  }

  &-title {
    text-align: center;
    font-size: 22px;
    padding-bottom: 32px
  }
}

.integration {
  &-logo {
    height: 80px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  &-name {
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    padding: 0 8px 16px 0;
  }
}

.no-inner-padding .ant-popover-inner-content {
  padding: 0;
}

.progress {
  &-container {
    width: 170px;
    font-size: 12px;
  }

  &-gauge {
    display: inline-block;
    width: 120px;
    margin-right: 8px;
  }

  &-back {
    width: 100%;
    height: 3px;
    position: relative;
    display: inline-block;
    background-color: #f1f5f9;
    border-radius: 100px;
    vertical-align: middle;
  }

  &-line {
    height: 3px;
    background-color: #AED581;
    border-radius: 100px;
  }
}

// .cohort {
//   // display: block;
//   width: 100%;
//   overflow-x: auto;
//   white-space: nowrap;
//   table-layout: fixed;
//   tr > td, tr > th {
//     border-bottom:  1px solid @border-color-split;
//   }
//   tr > td {
//     text-align: center;
//     vertical-align: middle;
//   }
// }

.second-menu {

  &-title {
    padding: @space-m;
    font-weight: bold;
  }

  &-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      cursor: pointer;
      padding: @space-s @space-l;
      border-left: 2px solid rgba(0,0,0,0);

      &.active, &:hover {
        border-left: 2px solid rgba(78,108,255,0.4);; //rgba(225,245,254 ,0.7);
        
      }
    }
  }
}


.segment-form {
  &-group {  
    border-bottom: 1px solid #CFD8DC;
    padding: @space-m 0;
    // display: table;
    // width: 100%;
  }

  &-condition {
    margin-left: 60px;

    &:not(:last-child) {
      border-bottom: 1px solid #CFD8DC;
    }
    position: relative;
    // vertical-align: middle;
    // text-align: center;

    &-separator {
      position: absolute;
      left: -35px;
      border-right: 1px solid #CFD8DC;
      top: 0;
      bottom: 0;

      &.half {
        bottom: 25px;
      }
    }

    &-or, &-and {
      position: absolute;
      left: -55px;
      top: 16px;
      height: 24px;
      line-height: 24px;
      background-color: #fafafa;
      border: 1px solid #CFD8DC;
      border-radius: 3px;
      padding: 0 @space-s;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      width: 42px;
    }
  }

  // &-field {
  //   font-weight: 600;
  // }
}
.segment-form-group, .segment-form-condition-separator, .segment-form-condition-and, .segment-form-condition-or {
  &.color-1 { border-color: #03A9F4; }
  &.color-2 { border-color: #4CAF50; }
  &.color-3 { border-color: #E91E63; }
  &.color-4 { border-color: #795548; }
  &.color-5 { border-color: #FF9800; }
  &.color-6 { border-color: #009688; }
  &.color-7 { border-color: #9C27B0; }
  &.color-8 { border-color: #FFC107; }
  &.color-9 { border-color: #607D8B; }
  &.color-10 { border-color: #CDDC39; }
  &.color-11 { border-color: #3F51B5; }
}


.scenario {
  &-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 100 !important;
  }

  &-editor {
    position: relative;
    overflow-x: auto;
    transition: all .2s ease;
    // padding-bottom: 100px;

    border: 1px solid white;
    background-color: white;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDEwIEwgNDAgMTAgTSAxMCAwIEwgMTAgNDAgTSAwIDIwIEwgNDAgMjAgTSAyMCAwIEwgMjAgNDAgTSAwIDMwIEwgNDAgMzAgTSAzMCAwIEwgMzAgNDAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2UwZTBlMCIgb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjEiLz48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZTBlMGUwIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=");
    background-position: 19px 8px;
    // padding-top: 70px;
    max-height: 600px;
    min-height: 50vh;

    &.expanded {
      position: fixed;
      top: 20px;
      left: 20px;
      bottom: 20px;
      right: 20px;
      max-height: inherit;
      border-radius: 4px;
      box-shadow: 0px 0px 100px 60px rgba(0,0,0,0.2);
      z-index: 100 !important;
    }

    &-expand {
      z-index: 1000;
      display: inline-block;
      position: absolute;
      right: 24px;
      top: 24px;
    }

    &-node {
      position: relative;
      width: 200px;
      height: 200px;
      text-align: center;
      padding: 0;
      // vertical-align: middle;
    }

    &-spacer {
      position: absolute;
      left: 98px;
      width: 2px;
      height: 200px;
      background-color: #9575CD;
    }

    &-add {
      position: relative;
      height: 100px;
      width: 100px;
      border-bottom: 2px solid #9575CD;

      &.from-else {
        border-bottom: none;
      }

      &-button {
        position: absolute;
        top: 80px;
        right: -18px;
      }
    }

    &-start {
      position: relative;
      height: 100px;
      width: 100px;
      margin-left: 100px;
      border-bottom: 2px solid #9575CD;

      &-icon {
        position: absolute;
        top: 75px;
        right: 77.5px;
        background-color: white;
        border-radius: 4px;
        border: 2px solid #00C853;
        height: 45px;
        width: 45px;
        line-height: 43px;
        text-align: center;
        font-size: 16px;
        color: #00C853;
      }
    }
  
    &-bottom {
      position: absolute;
      bottom: 0;
      height: 80px;
      width: 100px;
      border-right: 2px solid #9575CD;
    }
  
    &-top {
      position: absolute;
      top: 0;
      height: 80px;
      width: 100px;
      border-right: 2px solid #9575CD;
    }
  
    &-left {
      position: absolute;
      top: 0;
      height: 100px;
      width: 100px;
      border-bottom: 2px solid #9575CD;
    }
  
    &-right {
      position: absolute;
      top: 0;
      right: 0;
      height: 100px;
      width: 100px;
      border-bottom: 2px solid #9575CD;
    }

    &-action, &-operator {
      position: relative;
      height: 100px;
      width: 200px;
      // border-bottom: 2px solid #9575CD;

      &-icon {
        position: absolute;
        cursor: pointer;
        top: 75px;
        right: 77.5px;
        background-color: white;
        border-radius: 4px;
        border: 2px solid #E91E63;
        height: 45px;
        width: 45px;
        line-height: 40px;
        text-align: center;
        font-size: 22px;
        color: #E91E63;

        &:hover {
          box-shadow: @box-shadow-base;
        }
      }

      &-type {
        position: absolute;
        bottom: 35px;
        font-size: 12px;
        font-weight: 600;
        width: 200px;
        text-align: center;
        
        span {
          background-color: white;
        }
      }

      &-details {
        position: absolute;
        z-index: 1000;
        top: 125px;
        font-size: 12px;
        width: 200px;
        text-align: center;
      }
    }

    &-operator-icon {
      border-color: #9575CD;
      color: #9575CD;
    }

    &-operator-if {
      &-yes {
        position: absolute;
        right: 30px;
        bottom: 5px;
        color: #4CAF50;
      }
      &-no {
        position: absolute;
        right: 80px;
        bottom: -70px;
        color: #FF5722;
      }
    }

    &-operator-split {
      &-a {
        position: fixed;
        // left: 138px;
        // bottom: 5px;
        font-size: 12px;
        margin-top: 75px;
        width: 100px;
        margin-left: 50px;
        background-color: white;
      }
      &-b {
        position: fixed;
        background-color: white;
        font-size: 12px;
        width: 120px;
        margin-top: 150px;
        margin-left: -60px;
      }
    }
  }
}

.cascader-wide {
  .ant-cascader-menu:nth-child(1) {
    width: 200px;
  }
  .ant-cascader-menu {
    height: 350px;
  }
}

.aspect-ratio {

  position: relative;
  display: block;
  background: #fafbfc;
  padding: 0;

  width: 50px;
  padding-bottom: 50px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;

  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid rgba(195,207,216,0.3);
  }

  &-content {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}

.image-dummy {
  position: absolute;
  top: 0;
  color: transparent;
  fill: #d3dbe2;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.info-radio {
  &-item {
    border: 1px solid @border-color-base;
    border-radius: @border-radius-base;
    padding: 16px 24px 24px 16px;
    background-color: @radio-button-bg;
    color: @radio-button-color;
    height: 100%;

    &-icon {
      font-size: 24px;
      opacity: 0.6;
    }

    &-title {
      font-weight: 600;
      font-size: 14px;
      padding-bottom: 12px;
    }

    &-content {
      opacity: 0.7;
      font-size: 13px;
    }

    &:hover:not(.disabled) {
      cursor: pointer;
      border-color: @radio-button-hover-color;
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.checked {
      color: @radio-button-active-color;
      background-color: @radio-button-checked-bg;
      border-color: @radio-button-active-color;
    }

    &.disabled.checked {
      background-color: @disabled-bg;
      color: @radio-disabled-button-checked-color;
    }
  }
}



.Iframe {
  // border: 1px solid @border-color-base;
  // border-radius: @border-radius-base;
  position: relative;
  // z-index: 10;  
  &-normal {
    height: 200px;
    width: 100%;
  }
  &.expanded {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100% !important;
    border: none;
    border-radius: 0;
    z-index: 100;
  }

  &-actions {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
  }
}

.macro-content {
  max-height: 200px;
  transition: all 0.1s ease;
  overflow: scroll;
  &::after {
    content: ' '
  }

  code {
    overflow-x: scroll;
  }
  &.expanded {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: inherit;
    z-index: 10;
  }

  &-expand-button {
    position: fixed;
    top: 12px;
    right: 12px;
    z-index: 1000;

  }
}

.phone-preview {
  margin: 0 auto;
  width: 350px;
  height: 663px;
  overflow: hidden;
  background-image: url("../images/phone.png");

  .sms-container {
    width: 250px;
    margin: 100px 0 0 50px;
  }
  .title {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: @space-s;
  }
  .sms {
    background-color: rgba(255,255,255,0.9);
    padding: @space-s;
    border-radius: 8px;
    font-size: 12px;
  }
}

#previewContent {
  border-left: 1px solid @border-color-base;
}

// login with amazon
// .lwa {
//   display: inline-block;
//   width: 312px;
//   height: 64px;
//   background: url("https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_312x64.png");
  
//   &:hover {
//     background: url("https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_312x64_pressed.png");
//   }
// }
