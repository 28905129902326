/* -a-ll */

@space-xs: 4px;
@space-s: 8px;
@space-m: 16px;
@space-l: 32px;
@space-xl: 64px;

.padding-a-n { padding: 0 !important; }
.padding-a-xs { padding: @space-xs !important; }
.padding-a-s { padding: @space-s !important; }
.padding-a-m { padding: @space-m !important; }
.padding-a-l { padding: @space-l !important; }
.padding-a-xl { padding: @space-xl !important; }

.margin-a-n { margin: 0 !important; }
.margin-a-xs { margin: @space-xs !important; }
.margin-a-s { margin: @space-s !important; }
.margin-a-m { margin: @space-m !important; }
.margin-a-l { margin: @space-l !important; }
.margin-a-xl { margin: @space-xl !important; }

/* -v-ertical */

.padding-v-n { padding-top: 0 !important; padding-bottom: 0 !important; }
.padding-v-xs { padding-top: @space-xs !important; padding-bottom: @space-xs !important; }
.padding-v-s { padding-top: @space-s !important; padding-bottom: @space-s !important; }
.padding-v-m { padding-top: @space-m !important; padding-bottom: @space-m !important; }
.padding-v-l { padding-top: @space-l !important; padding-bottom: @space-l !important; }
.padding-v-xl { padding-top: @space-xl !important; padding-bottom: @space-xl !important; }

.margin-v-n { margin-top: 0 !important; margin-bottom: 0 !important; }
.margin-v-xs { margin-top: @space-xs !important; margin-bottom: @space-xs !important; }
.margin-v-s { margin-top: @space-s !important; margin-bottom: @space-s !important; }
.margin-v-m { margin-top: @space-m !important; margin-bottom: @space-m !important; }
.margin-v-l { margin-top: @space-l !important; margin-bottom: @space-l !important; }
.margin-v-xl { margin-top: @space-xl !important; margin-bottom: @space-xl !important; }

/* -h-orizontal */

.padding-h-n { padding-left: 0 !important; padding-right: 0 !important; }
.padding-h-xs { padding-left: @space-xs !important; padding-right: @space-xs !important; }
.padding-h-s { padding-left: @space-s !important; padding-right: @space-s !important; }
.padding-h-m { padding-left: @space-m !important; padding-right: @space-m !important; }
.padding-h-l { padding-left: @space-l !important; padding-right: @space-l !important; }
.padding-h-xl { padding-left: @space-xl !important; padding-right: @space-xl !important; }

.margin-h-n { margin-left: 0 !important; margin-right: 0 !important; }
.margin-h-xs { margin-left: @space-xs !important; margin-right: @space-xs !important; }
.margin-h-s { margin-left: @space-s !important; margin-right: @space-s !important; }
.margin-h-m { margin-left: @space-m !important; margin-right: @space-m !important; }
.margin-h-l { margin-left: @space-l !important; margin-right: @space-l !important; }
.margin-h-xl { margin-left: @space-xl !important; margin-right: @space-xl !important; }

/* -t-op */

.padding-t-n { padding-top: 0 !important; }
.padding-t-xs { padding-top: @space-xs !important; }
.padding-t-s { padding-top: @space-s !important; }
.padding-t-m { padding-top: @space-m !important; }
.padding-t-l { padding-top: @space-l !important; }
.padding-t-xl { padding-top: @space-xl !important; }

.margin-t-n { margin-top: 0 !important; }
.margin-t-xs { margin-top: @space-xs !important; }
.margin-t-s { margin-top: @space-s !important; }
.margin-t-m { margin-top: @space-m !important; }
.margin-t-l { margin-top: @space-l !important; }
.margin-t-xl { margin-top: @space-xl !important; }

/* -b-ottom */

.padding-b-n { padding-bottom: 0 !important; }
.padding-b-xs { padding-bottom: @space-xs !important; }
.padding-b-s { padding-bottom: @space-s !important; }
.padding-b-m { padding-bottom: @space-m !important; }
.padding-b-l { padding-bottom: @space-l !important; }
.padding-b-xl { padding-bottom: @space-xl !important; }

.margin-b-n { margin-bottom: 0 !important; }
.margin-b-xs { margin-bottom: @space-xs !important; }
.margin-b-s { margin-bottom: @space-s !important; }
.margin-b-m { margin-bottom: @space-m !important; }
.margin-b-l { margin-bottom: @space-l !important; }
.margin-b-xl { margin-bottom: @space-xl !important; }

/* -l-eft */

.padding-l-n { padding-left: 0 !important; }
.padding-l-xs { padding-left: @space-xs !important; }
.padding-l-s { padding-left: @space-s !important; }
.padding-l-m { padding-left: @space-m !important; }
.padding-l-l { padding-left: @space-l !important; }
.padding-l-xl { padding-left: @space-xl !important; }

.margin-l-n { margin-left: 0 !important; }
.margin-l-xs { margin-left: @space-xs !important; }
.margin-l-s { margin-left: @space-s !important; }
.margin-l-m { margin-left: @space-m !important; }
.margin-l-l { margin-left: @space-l !important; }
.margin-l-xl { margin-left: @space-xl !important; }

/* -r-ight */

.padding-r-n { padding-right: 0 !important; }
.padding-r-xs { padding-right: @space-xs !important; }
.padding-r-s { padding-right: @space-s !important; }
.padding-r-m { padding-right: @space-m !important; }
.padding-r-l { padding-right: @space-l !important; }
.padding-r-xl { padding-right: @space-xl !important; }

.margin-r-n { margin-right: 0 !important; }
.margin-r-xs { margin-right: @space-xs !important; }
.margin-r-s { margin-right: @space-s !important; }
.margin-r-m { margin-right: @space-m !important; }
.margin-r-l { margin-right: @space-l !important; }
.margin-r-xl { margin-right: @space-xl !important; }