.sb-show-main.sb-main-padded {
    padding: 0 !important;
}

.cmeditor-transparent {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==) #FFFFFF;
}

.cmeditor-main {
    position: relative;
    // height: 100vh;
    // min-height: 100vh;
    /* background-color: white; */
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==);

    .ant-form-item-label {
        white-space: normal;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: inherit;
    }
}

.cmeditor-main.fullscreen {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh !important;
}
.cmeditor-topbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 56px;
    background-color: #15395b;
    padding: 12px;
    z-index: 1;
    /* box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2); */
    /* border-bottom: 1px solid ; */
}

.cmeditor-topbar .cmeditor-title {
    color: white;
    line-height: 32px;
    font-size: 18px;
}

.cmeditor-layout {
    /* display: flex; */
    position: relative;
    background-color: #f3f6fc;
}

.cmeditor-layout-left {
    position: absolute;
    width: 17%;
    top: 56px;
    left: 0;
    bottom: 0;
    background-color: #F5F5F5;

    &.preview {
        border-right: 1px solid @border-color-base;
        width: 35%;
        background-color: white;
    }
    
    .smooth-dnd-ghost {
        position: fixed !important;
        // margin-top: -130px;
        // margin-left: -30px;
        // display: none !important;
    }
}
// .cmeditor-layout-left.cmeditor-half, .cmeditor-layout-right.cmeditor-half {
//     width: 50%;
// }
.cmeditor-code-bg {
    background-color: #111d2c;
    padding: 24px;
}


.cmeditor-Iframe {
    text-align: center;
}

.cmeditor-layout-html {
    width: 65%;
    margin-left: 35%;
    background: white;
    padding-top: 56px;
}
.cmeditor-layout-html iframe {
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
}

.cmeditor-layout-middle {
    position: absolute;
    top: 0;
    left: 17%;
    right: 27%;
    bottom: 0;
    overflow-y: scroll;
    /* padding: 70px 32px 32px 32px; */
    /* box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.1); */
    border-right: 1px solid rgba(0,0,0,0.1);
    border-left: 1px solid rgba(0,0,0,0.1);
    // min-height: 100vh;
}

.cmeditor-layout-right {
    position: absolute;
    width: 27%;
    background-color: #F5F5F5;
      /* #111d2c; */
    /* padding: 24px; */
    top: 56px;
    right: 0;
    bottom: 0;
    overflow-y: hidden;
}

.cmeditor-page {
    padding-top: 56px;
    margin: 0 auto;
}

.cmeditor-menu-item {
    border-radius: 4px;
    border: 1px solid black;
    padding: 6px;
    cursor: move;
}

.cmeditor-block {
    box-sizing: border-box;
}
.cmeditor-block-content {
    position: relative;
    /* border: 1px dashed rgba(0,0,0,0); */
    /* hack for columns full parent height: */
    height: 100%; 
    /* box-shadow: inset 0px 0px 0px 1px @primary-color; */
    /* border: 1px solid #8BC34A; */
}
body:not(.smooth-dnd-no-user-select) .cmeditor-block-content.cmeditor-focused {
    /* box-shadow: inset 0px 0px 0px 1px @primary-color; */
    /* box-shadow: 0px 0px 3px 1px rgba(0,229,255 ,1); */
    z-index: 10;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0 ,0.5);
    /* border: 1px dashed @primary-color; */
}
body:not(.smooth-dnd-no-user-select) .cmeditor-block-content.cmeditor-selected {
    /* box-shadow: 0px 0px 3px 1px rgba(0,184,212 ,1); */
    box-shadow: 0px 0px 3px 1px #4E6CFF;
}

.cmeditor-block-container {
    /* box-shadow:inset 0px 0px 0px 1px #8BC34A; */
    border: 1px dashed blue;
}

.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    // permet de voir le box-shadow on focus blocks
    overflow: visible !important;
}
.smooth-dnd-draggable-wrapper {
    position: relative;
}

.cmeditor-path {
    line-height: 32px;
    color: white;
    font-size: 12px;
    font-weight: bold;
}
.cmeditor-path .anticon {
    vertical-align: middle;
}

.cmeditor-path-item {
    cursor: pointer
}
.cmeditor-path-item:hover {
    color: #E1F5FE;
}

.cmeditor-path-item-last {
    opacity: 0.5;
}
.cmeditor-path-divider {
    padding: 0 6px;
    opacity: 0.5;
}

.cmeditor-ghost-drag {
    z-index: 1000;
    transition: transform 0.18s ease;
    transform: rotateZ(5deg) scale(0.3);
    transform-origin: top center;
}

.cmeditor-ghost-drop {
    transition: transform 0.18s ease-in-out;
    transform: rotateZ(0deg)
}

.cmeditor-drop-preview {
    /* height: 200px; */
    /* background-color: red; */
    border: 2px dashed @primary-color;
    background-color: #e5f4f9;
    border-radius: 4px;
    max-height: 100px;
    overflow: hidden;
}

.cmeditor-selected-block-buttons {
    z-index: 10;
    position: absolute;
    top: 0;
    left: -30px;
    box-shadow: 0 0 7px 2px rgba(0,0,0,.08);
    /* border: 1px solid @primary-color; */
}

.cmeditor-selected-block-button {
    width: 24px;
    height: 24px;
    line-height: 20px;
    /* border-radius: 3px; */
    text-align: center;
    background-color: white;
    color: @primary-color;
    cursor: pointer;
}
.cmeditor-selected-block-button:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.cmeditor-selected-block-button:hover {
    background-color: #e5f4f9;
}
.cmeditor-selected-block-button.cmeditor-top-left {
    top: -24px;
    left: -24px;
    border-bottom-right-radius: 0;
    cursor: move;
}
.cmeditor-selected-block-button.cmeditor-top-right {
    top: -24px;
    right: -24px;
    border-bottom-left-radius: 0;
}
.cmeditor-selected-block-button.cmeditor-bottom-right {
    bottom: -24px;
    right: -24px;
    border-top-left-radius: 0;
}
.cmeditor-selected-block-button.cmeditor-bottom-left {
    bottom: -24px;
    left: -24px;
    border-top-right-radius: 0;
}
.cmeditor-ui-history-btns {
    position: absolute;
    background-color: #37474F;
    border-radius: 4px;
    bottom: 32px;
    right: 32px;
}

.cmeditor-ui-menu-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 24px;
    padding: 24px 24px 0 24px;
}

.cmeditor-ui-block {
    padding: 8px;
    /* background-color: #15395b; */
    /* background-color: #CFD8DC; */
    background-color: white;
    border-radius: 3px;
    cursor: move;
    box-sizing: border-box;
    border: 1px solid rgba(3,169,244,0);
    margin-bottom: 12px;
    /* color: white; */
    color: @primary-color;
    text-align: center;
}
.cmeditor-ui-saved-block {
    padding: 6px;
    /* background-color: #15395b; */
    /* background-color: #CFD8DC; */
    background-color: white;
    border-radius: 3px;
    cursor: move;
    box-sizing: border-box;
    border: 1px solid rgba(3,169,244,0);
    margin-bottom: 2px;
    /* color: white; */
    color: @primary-color;
    font-size: 12px;
}
.cmeditor-ui-block:hover, .cmeditor-ui-saved-block:hover {
    border: 1px solid rgba(3,169,244,1);
    /* background-color: white; */
    /* box-shadow: 0px 0px 8px 0px rgba(3,169,244,1); */
}
.cmeditor-ui-block.cmeditor-square {
    height: 80px;
    vertical-align: middle;
}
.cmeditor-ui-block-icon {
    font-size: 24px;
}

.cmeditor-ui-block-col {
    background-color: #CBD3FF;
    width: 100%;
    height: 10px;
}

.cmeditor-layout-left .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    overflow: auto;
}

.cmeditor-main .ant-form-vertical .ant-form-item, .cmeditor-form-item-align-right {
    flex-direction: row !important;
}
.cmeditor-layout-right .ant-form-item-label > label, label {
    font-size: 12px;
    height: 32px;
}

.cmeditor-form-item-align-right .ant-form-item-control-input-content {
    text-align: right;
}
.cmeditor-form-item-align-right .ant-form-item-control-input-content .ant-select {
    text-align: left;
}
.cmeditor-ui-link {
    color: #40a9ff;
    border-bottom: 1px solid #40a9ff;
    cursor: pointer;
}
.cmeditor-ui-link:hover {
    color: #003a8c;
    border-bottom: 1px solid #003a8c;
}
.cmeditor-color-picker-input-light {
    /* border: 1px solid #434343; */
    border-radius: 2px;
    cursor: pointer;
    min-width: 24px;
}
.cmeditor-color-picker-input-light:hover {
    border: 1px solid#40a9ff !important;
}

.cmeditor-padding-t-s { padding-top: 8px ;}
.cmeditor-padding-r-s { padding-right: 8px ;}
.cmeditor-padding-b-s { padding-bottom: 8px ;}
.cmeditor-padding-l-s { padding-left: 8px ;}

.cmeditor-padding-h-s { padding-left: 8px; padding-right: 8px; }
.cmeditor-padding-h-m { padding-left: 12px; padding-right: 12px; }
.cmeditor-padding-h-l { padding-left: 24px; padding-right: 24px; }

.cmeditor-margin-t-s { margin-top: 8px ;}
.cmeditor-margin-r-s { margin-right: 8px ;}
.cmeditor-margin-b-s { margin-bottom: 8px ;}
.cmeditor-margin-l-s { margin-left: 8px ;}

.cmeditor-margin-t-m { margin-top: 12px ;}
.cmeditor-margin-r-m { margin-right: 12px ;}
.cmeditor-margin-b-m { margin-bottom: 12px ;}
.cmeditor-margin-l-m { margin-left: 12px ;}

.cmeditor-margin-t-l { margin-top: 24px ;}
.cmeditor-margin-r-l { margin-right: 24px ;}
.cmeditor-margin-b-l { margin-bottom: 24px ;}
.cmeditor-margin-l-l { margin-left: 24px ;}


.cmeditor-toolbar-overlay {
    /* background-color: red; */
    height: 20px;
    bottom: -6px;
    left: 0;
    right: 0;
    height: 7px;
    position: absolute;
    cursor: inherit !important;
}

.cmeditor-toolbar {
    text-align: left;
    display: none;
    position: absolute;
    z-index: 100;
    left: 2px;
    top: -5px;
    white-space: nowrap;
    margin-top: -42px;
    margin-left: -4px;
    /* background-color: #F5F5F5; */
    background-color: white;
    border-radius: 2px;
    /* border: 2px solid #F5F5F5; */
    padding: 2px 8px;
    box-shadow: 0 0 7px 2px rgb(0 0 0 / 8%);
    color: black;
    transition: opacity 0.3s;
    height: 40px;
    line-height: 36px;
}

.cmeditor-toolbar-button, .cmeditor-toolbar > * {
    cursor: pointer;
    display: inline-block;
    padding: 0 6px;
}
.cmeditor-toolbar-button > .anticon {
    vertical-align: middle;
}
.cmeditor-toolbar svg {
    vertical-align: inherit;
}
.cmeditor-toolbar-button {
    cursor: pointer;
    display: inline-block;
    color: #37474F;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 2px;
    line-height: 22px;
    /* border-right: 2px solid #F5F5F5; */
    margin-right: 8px;
}
.cmeditor-toolbar-button:hover, .cmeditor-toolbar-button.active {
    color: @primary-color;
}
.cmeditor-toolbar-button.active {
    /* background-color: #CFD8DC; */
    /* background-color: rgba(3,169,244,0.1); */
    box-shadow: inset 0px 0px 1px 1px @primary-color;
    /* color: inherit; */
}
.cmeditor-toolbar-color {
    width: 24px;
    border-radius: 2px;
    vertical-align: middle;
    box-shadow: inset 0px 0px 1px 1px #d9d9d9;
    margin-left: 6px;
}
.cmeditor-toolbar-color:hover {
    box-shadow: inset 0px 0px 1px 1px @primary-color;
}