
.fm-container {
    position: relative;
    vertical-align: top;
}

.fm-centered-block {
    position: absolute;
    line-height: 1.5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
}

.fm-centered-block > div {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fm-left-panel {
    width: 30%;
    display: table-cell;
    padding: 12px;
}

.fm-right-panel {
    width: 70%;
    display: table-cell;
    padding-left: 24px;
}

.fm-tree .ant-tree-switcher {
    display: none;
}

.fm-block {
    box-shadow: 1px 1px 0 rgba(0,0,0,.1);
    border-radius: 4px;
    background: #fff;
}

.fm-topbar {
    display: flex;
    gap: 24px;
    margin-bottom: 12px;
}
.fm-topbar-left {
    width: 25%;
    overflow: scroll;
}
.fm-topbar-right {
    width: 75%;
    /* overflow: hidden; */
}
.fm-topbar-right-buttons {
    display: flex;
    gap: 12px;
    flex-direction: row-reverse;
}

.fm-tree {
    margin: 12px;
}
.fm-tree-item {
    position: relative;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
    border-radius: 3px;
}
.fm-tree-item:hover {
    color: #4e6cff;
    background: rgba(240, 244, 255, 0.5);
}
.fm-tree-item.selected {
    background: #f0f4ff;
}
.fm-tree-item-folder {
    padding-left: 8px;
    padding-right: 3px;
}
.fm-tree-item-icon {
    position: absolute;
    top: 2px;
    right: 8px;
    padding: 4px;
    line-height: 12px;
    border-radius: 50%;
}
.fm-tree-item-icon:hover {
    background: #C5CAE9;
}
.fm-table-item-icon {
    padding: 4px;
    line-height: 12px;
    border-radius: 50%;
}
.fm-table-item-icon:hover {
    background: #C5CAE9;
}
.fm-upload-progress {
    text-align: left;
    line-height: 32px;
    font-size: 12px;
}
.fm-table-content  {
    font-size: 12px;
}
.fm-action-icon, a.fm-action-icon:visited, a.fm-action-icon:focus {
    padding: 4px 6px;
    color: inherit !important;
}
.fm-action-icon:hover {
    color: #4e6cff;
    cursor: pointer;
}
.valign-middle {
    vertical-align: middle;
    /* background-color: red; */
}